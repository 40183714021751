import noUiSlider from 'nouislider';

let looping_container;
let loop;
let reset_trigger;
// aria-label="Playbar"
// w-playbar-wrapper
if (typeof Wistia !== 'undefined') {
	Wistia.plugin('hdplooping', (video, customOptions) => {
		const looping_template = document.getElementById(
			'hdp-looping-template',
		);
		if (looping_template === null) {
			return false;
		}

		looping_container = looping_template
			.querySelector('.hdp-looping')
			.cloneNode(true);
		const looping_controls = looping_container.querySelector(
			'.hdp-looping-controls',
		);
		let control_container = video.grid.center.querySelector(
			'.w-bottom-bar-lower',
		);
		let control_bar = video.grid.center.querySelector(
			'.w-bottom-bar-lower div[role="slider"]',
		);
		let control_bar_right = video.grid.center.querySelector(
			'.w-bottom-bar-right',
		);
		let control_bar_left =				video.grid.center.querySelector('.w-bottom-bar-left');
		let control_button = video.grid.center.querySelector(
			'.w-vulcan-button-wrapper',
		);
		const looping_message = looping_container.querySelector(
			'.hdp-looping-message',
		);

		const initialStartMinute = 0;
		const initialEndMinute = parseInt(video.duration());
		const step = 1;
		let loopstart = initialStartMinute;
		let loopend = initialEndMinute;
		const slider_el = looping_container.querySelector('.hdp-looping-slider');
		const start_end_trigger = looping_container.querySelector('.hdp-trigger');
		let loop = false;
		let is_sliding = false;

		const slider = noUiSlider.create(slider_el, {
			start: [
				initialStartMinute, initialEndMinute,
			],
			connect: true,
			step,
			range: {
				min: initialStartMinute,
				max: initialEndMinute,
			},
		});

		const slider_handle_right =				looping_container.querySelector('.noUi-handle-upper');
		const lower_handle =				looping_container.querySelector('.noUi-handle-lower');
		const handles = looping_container.querySelectorAll('.noUi-origin');
		if (slider_handle_right) {
			const handle_time = document.createElement('span');
			handle_time.setAttribute('class', 'hdp-loop-end-time');
			slider_handle_right.appendChild(handle_time);
		}

		const leftValue = looping_container.querySelectorAll(
			'.hdp-loop-start-time',
		);
		const rightValue =				looping_container.querySelectorAll('.hdp-loop-end-time');

		const convertValuesToTime = function (values, handle) {
			let hours = 0;
			let minutes = 0;

			if (handle === 0) {
				loopstart = values[0];
				hours = convertToHour(values[0]);
				minutes = convertToMinute(values[0], hours);
				for (let x = 0; x < leftValue.length; x++) {
					leftValue[x].innerHTML = formatHoursAndMinutes(
						hours,
						minutes,
					);
				}
				if (loop) {
					video.time(values[0]);
				}
				return;
			}

			hours = convertToHour(values[1]);
			minutes = convertToMinute(values[1], hours);

			for (let i = 0; i < rightValue.length; i++) {
				rightValue[i].innerHTML = formatHoursAndMinutes(hours, minutes);
			}
			loopend = values[1];

			if (loop) {
				video.time(values[1]);
			}
		};

		var convertToHour = function (value) {
			return Math.floor(value / 60);
		};
		var convertToMinute = function (value, hour) {
			return value - hour * 60;
		};
		var formatHoursAndMinutes = function (hours, minutes) {
			if (hours.toString().length == 1) {
				hours = `0${hours}`;
			}
			if (minutes.toString().length == 1) {
				minutes = `0${minutes}`;
			}
			return `${hours}:${minutes}`;
		};

		slider.on('slide', (values, handle) => {
			is_sliding = true;
			convertValuesToTime(values, handle);
		});

		slider.on('change', () => {
			is_sliding = false;
			video.time(loopstart);
		});

		video.ready(() => {
			video.grid.center
				.querySelector('.w-vulcan-v2')
				.appendChild(looping_container);
			(control_container = video.grid.center.querySelector(
				'.w-bottom-bar-lower',
			)),
			(control_bar = video.grid.center.querySelector(
				'.w-bottom-bar-lower div[role="slider"]',
			));
			control_bar_right = video.grid.center.querySelector(
				'.w-bottom-bar-right',
			);
			control_bar_left =				video.grid.center.querySelector('.w-bottom-bar-left');
			control_button = video.controls.fullscreenButton.buttonElem;
			bind_controls_visibility();

			setTimeout(() => {
				realign_elements();
				convertValuesToTime([
					initialStartMinute, initialEndMinute,
				], 0);
				convertValuesToTime([
					initialStartMinute, initialEndMinute,
				], 1);
			}, 1000);
		});

		video.bind('secondchange', (s) => {
			if (
				loop
				&& (s >= parseInt(loopend) || s < parseInt(loopstart))
				&& !is_sliding
			) {
				video.time(loopstart);
			}
		});

		//	video.bind( 'inputcontextchange', function ( visible ) {
		//
		//		var el_to_hide = looping_controls;
		//		if ( loop ) {
		//			el_to_hide = looping_container;
		//		}
		//
		//		if ( 'undefined' === typeof visible ) {
		//			el_to_hide.classList.remove( 'show' );
		//		} else {
		//			el_to_hide.classList.add( 'show' );
		//		}
		//	} );

		start_end_trigger.addEventListener('click', (e) => {
			e.preventDefault();
			const current_time = parseInt(video.time());
			if (start_end_trigger.classList.contains('start')) {
				start_end_trigger.classList.remove('start');
				start_end_trigger.classList.add('end');
				slider_el.noUiSlider.set([
					current_time, null,
				]);
				convertValuesToTime([
					current_time,
				], 0);
				lower_handle.classList.add('show');
				return;
			}
			if (start_end_trigger.classList.contains('end')) {
				start_end_trigger.classList.remove('end');
				start_end_trigger.classList.add('start');
				start_end_trigger.classList.add('hide');
				slider_el.noUiSlider.set([
					null, current_time,
				]);
				convertValuesToTime([
					0, current_time,
				], 1);
				looping_controls.classList.add('show');
				slider_handle_right.classList.add('show');
				loop = true;
				video.time(loopstart);
				video.showControls();
				show_looping_message();
			}
		});

		reset_trigger = function () {
			loop = false;
			start_end_trigger.classList.remove('hide');
			lower_handle.classList.remove('show');
			slider_handle_right.classList.remove('show');
			looping_message.classList.remove('show');
			convertValuesToTime([
				initialStartMinute, initialEndMinute,
			], 0);
			convertValuesToTime([
				initialStartMinute, initialEndMinute,
			], 1);
		};

		function show_looping_message() {
			looping_message.classList.add('show');
			setTimeout(() => {
				looping_message.classList.remove('show');
			}, 3000);
		}

		function realign_elements() {
			if (!control_bar) {
				return;
			}
			video.showControls();
			setTimeout(() => {
				const width =					window.innerWidth
					|| document.documentElement.clientWidth
					|| document.body.clientWidth;
				if (width > 437) {
					looping_controls.style.bottom =						`${control_bar.offsetHeight}px`;
					slider_el.style['margin-right'] =						`${control_container.offsetWidth
						- control_bar.offsetWidth
						- control_bar_left.offsetWidth
						- control_bar.offsetLeft
						- 10
						 }px`;
					slider_el.style['margin-left'] =						`${control_bar_left.offsetWidth
						- 10
						+ control_bar.offsetLeft
						 }px`;
				} else {
					looping_controls.style.bottom =						`${control_bar.offsetHeight - 16}px`;
					slider_el.style['margin-right'] = 0;
					slider_el.style['margin-left'] = 0;
				}
			}, 100);
		}

		function bind_controls_visibility() {
			const targetNode = document.querySelector('.w-bottom-bar');
			const observer = new MutationObserver(() => {
				let el_to_hide = looping_controls;
				if (loop) {
					el_to_hide = looping_container;
				}
				if (
					targetNode.style.display === 'none'
					|| targetNode.style.opacity < 1
				) {
					el_to_hide.classList.remove('show');
				} else {
					el_to_hide.classList.add('show');
				}
			});
			observer.observe(targetNode, { attributes: true, childList: true });
		}

		window._wq = window._wq || [];
		window._wq.push((W) => {
			function LoopControl(video) {
				this.video = video;
			}

			LoopControl.prototype.mountButton = function (buttonRoot) {
				if (!buttonRoot) {
					return;
				}
				buttonRoot.innerHTML =					'<i class="material-icons-round">loop</i>';
				loop = false;
			};

			LoopControl.prototype.onClickButton = function (event) {
				looping_container.classList.toggle('show');
				if (loop) {
					loop = false;
					reset_trigger();
				} else {
					loop = true;
				}
			};

			LoopControl.prototype.onControlPropsUpdated = function (prevProps) {
				if (this.props.videoWidth !== prevProps.videoWidth) {
					realign_elements();
				}
			};

			LoopControl.handle = 'loopButton';
			LoopControl.type = 'control-bar-right';
			LoopControl.sortValue = 5000;

			LoopControl.shouldMount = function (video) {
				return true;
			};

			W.defineControl(LoopControl);
		});
	});
}
